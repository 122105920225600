.Aside{
    width: 100%;
}

.Box{
    box-shadow: 0 10px 30px 0 rgba(127, 127, 127, 0.3);
    background: #fff;
    border-radius: 10px;
}
.ContainerBox{
    padding: 1.1rem;
}

.customerEditBtn {
    color: #2c6ecb;
    cursor: pointer;
    font-size: 14px;
}

.customerDetail {
    color: #2c6ecb;
    font-size: 14px;
    margin-top: 5px;
}

.defaultAddress {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.defaultAddressHeader {
    margin: auto 0px;
    font-size: 12px;
    font-weight: 600;
}

.addNewAddress {
    color: #2c6ecb;
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
}

.inputFields {
    width: 100%;
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #C2C5C9;
}

.tagsBox{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.tagsHeader {
    margin: auto 0px;
    padding: 6px;
}

.tagsChangeBtn {
    color: #2C6ECB;
    text-decoration: none;
    padding-right: 0px;
    cursor: pointer;
}

.tagsInput {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.tagsContentBox {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    margin-top: 5px;
}

.tagsContent {
    background-color: #E4E5E7;
    padding: 2px 10px;
    margin: 3px 3px;
    border-radius: 4px;
}

.UpdateSalesDialog {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1300;
    position: fixed;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0%;
}